const PORTFOLIO = [
  {
    src: '/assets/imgs/portfolio/carswork.jpg',
    title: 'Cars work',
    popupImageSrc: [
      '/assets/imgs/portfolio/carsworkhome.jpg',
      '/assets/imgs/portfolio/carswork2_home.JPG',
    ],
    popupSiteLink: 'https://carswork.com',
    popupMissionText: '(Ukrainian project)<br/>Service for ordering a tow truck.<br/>It is possible to register as a client and register as a tow truck owner.<br/>Our team participated in the development of the backend and partly the frontend.<br/><br/>Developed:<br/>- There is an opportunity to register<br/>- It is possible to register as a client<br/>- It is possible to register as the owner of a tow truck<br/>- It is possible to order a tow truck to your city<br/>- It is possible to leave a review about the tow truck<br/>- It is possible to order a tow truck again<br/>- It is possible to indicate on the map where you need to order a tow truck',
  	popupDbs: 'MongoDB',
  	popupDevKnowledge: 'OOP & OOD / TDD & BDD / API (REST API & Postman & Swagger & API Doc)',
  	popupFE: 'Angular (Typescript)',
    popupBE: 'Node.js (Express)',
    urlName: 'carswork',
  },
  {
    src: '/assets/imgs/portfolio/createensemble.PNG',
    title: 'Create Ensemble',
    popupImageSrc: ['/assets/imgs/portfolio/createensemble_home.png'],
    popupSiteLink: 'https://www.createensemble.com/',
    popupMissionText: '(USA project)<br/>There is a social network for creative people. Something like Upwork.<br/>It is possible to register as a user and publish your biography and, as a company, by posting your information, to hire employees for work.<br/>Internal correspondence between me and users, the ability to create groups, add to friends.<br/>The mentoring functionality is also separately implemented, where any registered user can be a mentor or student.',
  	popupDbs: 'PostgreSQL',
  	popupDevKnowledge: 'OOP & OOD / TDD & BDD / API (REST API & Postman & Swagger & API Doc)',
  	popupFE: 'Vue',
  	popupBE: 'Java',
    urlName: 'createensemble',
  },
  {
    src: '/assets/imgs/portfolio/rfs.PNG',
    title: 'Run for Stuff',
    popupImageSrc: [
      '/assets/imgs/portfolio/rfs_home.PNG',
      '/assets/imgs/portfolio/rfs2_home.JPG',
      '/assets/imgs/portfolio/rfs3_home.JPG',
    ],
    popupSiteLink: 'https://runforstuff.com',
    popupMissionText: '(Canadian project)<br/>A hybrid of Groupon (coupon service) and Pokémon Go. The user downloads the application and finds gifts in certain places (discounts from different establishments or stores).<br/>Our team develops a backend and an administrative panel for the application, namely, company registration, payment of discount coupons, mathematical calculations of winning. Also develop the ability to make users moderators with certain access rights. Authentication via Facebook, Google.',
  	popupDbs: 'MongoDB',
  	popupDevKnowledge: 'OOP & OOD / TDD & BDD / API (REST API & Postman & Swagger & API Doc)',
  	popupFE: 'React (Redux Saga)',
  	popupBE: 'Node.js (Loopback TS)',
    urlName: 'rfs',
  },
  {
    src: '/assets/imgs/portfolio/groundworks.PNG',
    title: 'Groundworks',
    popupImageSrc: [
      '/assets/imgs/portfolio/groundworks_home.PNG',
      '/assets/imgs/portfolio/groundworks2_home.PNG',
    ],
    popupMissionText: '(Canadian project)<br/>Service for tracking the work of employees. Application and administrative web version.<br/><br/>Developed for client part:<br/>- Login page<br/>- Dashboard<br/>- Employers<br/>- Forms<br/>- Site locations<br/>- Calendar<br/>- Analytics<br/>- Inventory<br/>- Add employer<br/>- Add site<br/>- Add form<br/>- Certification page<br/>- Worker`s page<br/><br/>Developed for admin part:<br/>- Login page<br/>- Dashboard<br/>- Employers<br/>- Details of employer<br/>- Forms<br/>- Add new employer<br/>- Employer activity<br/>- Notifications<br/>- Form builder<br/>- Calendar<br/>- Locations<br/><br/>Developed for mobile version:<br/>- Login page<br/>- Create acc<br/>- Home page<br/>- Form<br/>- Emergency contact<br/>- Calendar<br/>- Equipment<br/>- Create report<br/>- Weather site page<br/>- Weather API<br/>- Notifocations<br/>- Daily tasks<br/>- Statistics<br/>- Detail statistic<br/>- Location service<br/>- Reports',
  	popupDbs: 'PostgreSQL',
  	popupDevKnowledge: 'OOP & OOD / TDD & BDD / API (REST API & Postman & Swagger & API Doc)',
  	popupFE: 'React (Redux Saga)',
    popupBE: 'Node.js (Nest TS)',
    popupAPP: 'React Native',
    urlName: 'groundworks',
  },
  {
    src: '/assets/imgs/portfolio/dental.png',
    title: 'UAE Dentist',
    popupImageSrc: [
      '/assets/imgs/portfolio/dental_home.JPG',
      '/assets/imgs/portfolio/dental2_home.JPG',
    ],
    popupSiteLink: 'https://dental.waf.com.ua/',
    popupMissionText: '(UAE project)<br/>Service for finding a dentist by the location of the dentist, or the clinic in which he works the client part (front-end and back-end) and the admin panel (front-end and back-end) were developed a lot of different functionality has been implemented:<br/>- Find the nearest dental clinics according to my location<br/>- View the location of the clinics on a map<br/>- Able to change the city or area<br/>- See the available treatments for each clinic<br/>- Filter available appointments based on my date input<br/>- Filter clinics based on supported insurance options<br/>- Filter clinics based on language spoken<br/>- Filter dentist based on nationality<br/>- Filter dentist based on gender<br/>- Filter dentist based on language spoken<br/>- Filter dentist based on available dates<br/>- Search for different treaments e.g.  Urgent care, General check-up, Hygienist Visit, Teeth Whitening.<br/>- Request a booking for future<br/>- Book available appointment for today or tomorrow<br/>- Verify my booking via E-mail account<br/>- Verify my booking via Apple account<br/>- Verify my booking via Google account<br/>- Verify my booking via Facebook account<br/>- Complete the booking form before confirming the appointment<br/>- Booking confirmation sent to me by E-mail<br/>- Verify my booking via WhatsApp number<br/>- Leave a review after my visit',
  	popupDbs: 'MongoDB',
  	popupDevKnowledge: 'OOP & OOD / TDD & BDD / API (REST API & Postman & Swagger & API Doc)',
  	popupFE: 'React',
  	popupBE: 'Node.js (Express)',
    urlName: 'dental',
  },
  {
    src: '/assets/imgs/portfolio/social.png',
    title: 'Social Network',
    popupImageSrc: [
      '/assets/imgs/portfolio/social_home.png',
      '/assets/imgs/portfolio/social2_home.png',
    ],
    popupMissionText: '(Ukrainian project)<br/>A new social network for communicating with friends, sharing photos, news, comments. Ability to create chats and groups.<br/><br/>Developed (page creation):<br/>- Account creation<br/>- The ability to add friends<br/>- The ability to send messages to friends<br/>- The ability to like your friends photos<br/>- The ability to share pictures<br/>- The ability to add people to friends<br/>- The ability to upload an Avatar<br/>- The ability to make information about yourself<br/>- The ability to communicate in a group',
  	popupDbs: 'PostgreSQL',
  	popupDevKnowledge: 'OOP & OOD / TDD & BDD / API (REST API & Postman & Swagger & API Doc)',
  	popupFE: 'React (Redux Thunk)',
  	popupBE: 'Node.js (Nest TS)',
    urlName: 'socialnetwork',
  },
  {
    src: '/assets/imgs/portfolio/shop.PNG',
    title: 'Buy All Shop ',
    popupImageSrc: ['/assets/imgs/portfolio/shop_home.PNG'],
    popupSiteLink: 'https://shop.waf.com.ua/',
    popupMissionText: 'Buy All is online shop with various product: clothes, shoes, watches, textile, jewelry, sports accessories. Shop consist of two part: the client part and the admin panel. Site was developed completely by us.<br/><br/>Main functions of Buy All (the client part):<br/>- Login with Google<br/>- Verify registration via Email<br/>- Add liked products in special list<br/>- Watch your comments and editing it<br/>- Edit your personal data<br/>- Change avatar, email, password<br/>- Evaluate the product<br/>- Choose size of product<br/>- Choose color of product<br/>- Search product<br/>- Send information about order to email<br/>- Contact with administrator via Facebook<br/>- Contact with administrator via Email<br/>- Leave a review<br/>- Filter products by popularity<br/>- Filter products by price<br/>- Filter products by date of appearance<br/>- Number of products per page (40, 60, 80, 100)',
  	popupDbs: 'PostgreSQL',
  	popupDevKnowledge: 'OOP & OOD / TDD & BDD / API (REST API & Postman & Swagger & API Doc)',
  	popupFE: 'NextJS (TS)',
  	popupBE: 'Node.js (Nest TS)',
    urlName: 'shop',
  },
  {
    src: '/assets/imgs/portfolio/shopadmin.png',
    title: 'Buy All Admin panel',
    popupImageSrc: [
      '/assets/imgs/portfolio/shopadmin_home.png',
      '/assets/imgs/portfolio/shopadmin2_home.png',
    ],
    popupMissionText: 'Buy All is online shop with various product: clothes, shoes, watches, textile, jewelry, sports accessories. Shop consist of two part: the client part and the admin panel. Site was developed completely by us.<br/><br/>Main functions of Buy All (the admin panel):<br/>- Search product<br/>- Search category<br/>- Edit category<br/>- Delete category<br/>- Add category<br/>- Edit product description, title, price, category, characteristics<br/>- Add and delete product`s photo<br/>- Disable product<br/>- Change availability of product<br/>- Change main photo of product<br/>- Delete product<br/>- Edit product<br/>- Edit user`s profile<br/>- Delete user<br/>- Create user<br/>- Add slide<br/>- Edit slide<br/>- Delete slide<br/>- Disable slide<br/>- Delete comment<br/>- Delete review<br/>- Watch order<br/>- Change the number of slides of new products<br/>- Change the number of slides of popularity products<br/>- Disable slides of new products<br/>- Disable slides of popularity products',
  	popupDbs: 'PostgreSQL',
  	popupDevKnowledge: 'OOP & OOD / TDD & BDD / API (REST API & Postman & Swagger & API Doc)',
  	popupFE: 'NextJS (TS)',
  	popupBE: 'Node.js (Nest TS)',
    urlName: 'shopadmin',
  },
  {
    src: '/assets/imgs/portfolio/connectoro.jpg',
    title: 'Connectoro',
    popupImageSrc: ['/assets/imgs/portfolio/connectoro_home.PNG'],
    popupMissionText: '(Irelandian project)<br/>Development of a backend (admin panel and the backend itself) for a dropshipping company.<br/><span>Developed:<br/>- The ability to track orders<br/>- The ability to set the order status<br/>- The ability to make wholesale orders<br/>- The ability to take an order<br/>- The ability to reject the order<br/>- Possibility to make cash on delivery<br/>- The ability to administer sellers<br/>- The ability to delete goods</span>',
  	popupDbs: 'MongoDB',
  	popupDevKnowledge: 'OOP & OOD / TDD & BDD / API (REST API & Postman & Swagger & API Doc)',
  	popupFE: 'React (Redux Thunk)',
  	popupBE: 'Node.js (Hapi)',
    urlName: 'connectoro',
  },
];

export default PORTFOLIO;
