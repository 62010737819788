const WORKS_WITH = [
  {
    title: 'Startups',
  },
  {
    title: 'Digital agencies',
  },
  {
    title: 'Creative studios',
  },
  {
    title: 'Design studios',
  },
  {
    title: 'Web projects',
  },
  {
    title: 'those',
    text: 'who need a high-quality product'
  }
];

export default WORKS_WITH;
